@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
body {
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Quicksand, 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

li.css-1djjboa-MuiButtonBase-root-MuiMenuItem-root {
  font-weight: 400;
  font-size: 0.9rem;
}
